import React from 'react'
import type { PageProps } from "gatsby"
import StandardLayout from '../layouts/standard'

const NotFoundPage = ({location}: PageProps) => (
	<StandardLayout location={location}>
		<h1 className="card-style-block-text">Can't find it.</h1>
		<hr />
		<p>That page doesn't exist - but I appreciate your enthusiasm.</p>
	</StandardLayout>
)

export default NotFoundPage
